@import "constants";

* {
  font-family: Axiforma, Roboto, sans-serif;
}

.component {
  padding-top: 60px;
  padding-bottom: 60px;
}

.titles {
  color: $secondary-color;
  font-size: $font-x-large;
  margin-bottom: 35px;
  font-weight: bold;
  @media screen and (max-width: 991px) {
    margin-bottom: 15px;
  }
}

.subtitle {
  margin-bottom: 70px;
  color: $secondary-color;
}

.centered {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.full-height-vh {
  min-height: 100vh;
}

.vertical-align {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.link-text {
  color: $secondary-color;
}

.link-text:hover {
  color: $secondary-color;
  text-decoration: none;
}

.banner-background-container {
  position: relative;
  transition: padding-top .3s ease-out;

  .banner-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -2px;

    background-size: auto 65%;
    background-repeat: no-repeat;
    //background-position: bottom;
    background-position: bottom right;
    background-image: url("/assets/images/particles.svg");
    @media screen and (max-width: 991px) {
      background-size: auto 55%;
    }

    @media screen and (max-width: 768px) {
      background-size: auto 50%;
    }

    @media screen and (max-width: 540px) {
      background-size: auto 45%;
    }
  }
}

.inner-shadow-top {
  box-shadow: inset 0 20px 40px -20px rgba(0, 0, 0, 0.4);
}

.btn {
  height: 48px;
  padding-left: 1em;
  padding-right: 1em;

  border-radius: 24px;
}

input {
  min-height: 48px;
  font-family: Roboto, sans-serif;
}

.orange-button {
  color: $tertiary-color;
  background-color: $secondary-color;
  border-width: 0;
  margin-bottom: 12px;

  @include shadow-secondary();
  @include gradient-secondary();
  &:active {
    @include shadow-pressed($shadow-primary);
    color: $white;
    background-color: $primary-color;
  }
}

.purple-button {
  color: white;
  background-color: $primary-color;
  border-width: 0;
  margin-bottom: 12px;

  @include shadow-primary();
  @include gradient-primary();
  &:active {
    @include shadow-pressed($shadow-primary);
    color: $white;
    background-color: $primary-color;
  }
}
