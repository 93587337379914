/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '../node_modules/bootstrap/scss/bootstrap';
@import "app/styles/global";
@import "app/styles/constants";
@import "app/styles/fonts";


.stopScroll {
  overflow: hidden;
}

.bottom-center-absolute-aligned {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
}



.text-find-out {
  color: $secondary-color;
}

.arrow {
  margin-bottom: 1.5rem;

  a {
    display: block;

    img {
      opacity: 0.4;
      max-width: 2.5rem;
      transition: opacity .5s;
    }
  }

  a:hover {
    text-decoration: none;

    img {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 540px) {
  .arrow {
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 1370px) {
  ul.navbar-nav {
    flex-wrap: wrap;
    max-width: 720px;
    margin-right: 200px;
    justify-content: center;
  }
}

.page-title {
  font-size: $font-x-large;
  font-weight: bold;
  padding-top: 10rem;
}

@media screen and (max-width: 768px)
{
  .page-title {
    padding-top: 4rem;
  }
}

.mat-dialog-title {
  font-family: Axiforma, Roboto, sans-serif;
  color: $quaternary-color;
}
